<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title style="height: 60px">
        <h4>แจ้งถอน COD</h4>
        <v-spacer />
      </v-card-title>
    </v-card>
    <!-- search -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="primary">fa-sliders-h</v-icon>
        <span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="6" md="2" lg="1" class="pr-0">
            <span>สถานะ : </span>
          </v-col>
          <v-col cols="6">
            <v-radio-group v-model="search.status" row>
              <v-radio
                v-for="n in radio_status"
                v-bind:key="n['value']"
                :label="n['text']"
                :value="n['value']"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="2" lg="1" class="pr-0">
            <span> วันที่ : </span>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
              ref="search_menu_date_from"
              v-model="search_menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search_date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_from"
                  @change="(value) => (computed_search_date_from = value)"
                  autocomplete="off"
                  label="เริ่ม"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_from"
                @input="
                  (search_menu_date_from = false),
                    $refs.search_menu_date_from.save(search_date_from)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-menu
              ref="search_menu_date_to"
              v-model="search_menu_date_to"
              :close-on-content-click="false"
              :return-value.sync="search_date_to"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computed_search_date_to"
                  @change="(value) => (computed_search_date_to = value)"
                  autocomplete="off"
                  label="สิ้นสุด"
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_date_to"
                @input="
                  (search_menu_date_to = false),
                    $refs.search_menu_date_to.save(search_date_to)
                "
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row row>
          <v-col cols="6" md="2" lg="1" class="pr-0"> </v-col>
          <v-col cols="12" md="2" class="pb-3">
            <v-btn dark color="primary" @click="load_data()" class="mr-3">ค้นหา</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- datatable แจ้งถอนเงิน -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="deep-orange lighten-1">fa-donate</v-icon>
        <span class="ml-2">รายการแจ้งถอน COD</span>
        <v-spacer />
        <vue-excel-xlsx
          :data="dataTable"
          :columns="json_fields"
          :filename="filename"
          :sheetname="sheetname"
        >
          <v-btn color="success" dark @click="exportexcel()" class="fn-14" outlined>
            Export To Excel
          </v-btn>
        </vue-excel-xlsx>
      </v-card-title>
      <v-divider />
      <v-card-text class="hidden-xs-only">
        <v-data-table
          :headers="headers"
          :items="dataTable"
          class="elevation-0 packhai-border-table"
          hide-default-footer
          id="datatablexs"
          :items-per-page="50"
          :mobile-breakpoint="0"
        >
          <template v-slot:item.Cancle="{ item }">
            <v-btn
              outlined
              v-if="item.statusId != 3 && item.statusId != 2"
              color="red"
              @click="cancletranfer(item)"
            >
              <span>ยกเลิก</span>
            </v-btn>
          </template>
          <template v-slot:item.Confirm="{ item }">
            <v-btn
              outlined
              v-if="item.statusId == 0"
              color="black"
              @click="ConfirmTranfer(item)"
            >
              <span>ดำเนินการ</span>
            </v-btn>
          </template>
          <template v-slot:item.Amount="{ item }">
            <v-btn
              outlined
              v-if="item.statusId == 1"
              color="orange"
              @click="opendialogupdatetranfer(item)"
            >
              <span>แจ้งยอด</span>
            </v-btn>
          </template>
          <template v-slot:item.Detail="{ item }">
            <a v-if="item.statusId == 2" @click="DetailTranfer(item)">รายละเอียด</a>
          </template>
          <template v-slot:item.Status="{ item }">
            <span v-if="item.statusId == 0" class="back--text darken-4">{{
              item.statusDescription
            }}</span>
            <span v-if="item.statusId == 1" class="orange--text darken-4">{{
              item.statusDescription
            }}</span>
            <span v-if="item.statusId == 2" class="success--text">{{
              item.statusDescription
            }}</span>
            <span v-if="item.statusId == 3" class="danger--text">{{
              item.statusDescription
            }}</span>
          </template>
          <template v-slot:item.Created="{ item }">
            {{ item.created == null ? "" : fomatDateTime(item.created) }}
            <!-- {{item.created}} -->
          </template>
          <template v-slot:item.NameShop="{ item }">
            {{ item.shopName == null ? "" : item.shopName }}
          </template>
          <template v-slot:item.Wallet="{ item }">
            {{ item.balanceLeft == null ? "0.00" : format_price(item.balanceLeft) }}
          </template>
          <template v-slot:item.Withdraw="{ item }">
            {{ item.amount == null ? "0.00" : format_price(item.amount) }}
          </template>
          <template v-slot:item.NameBranch="{ item }">
            {{ item.branchName == null ? "" : item.branchName }}
          </template>
          <template v-slot:item.Bank="{ item }">
            <span>
              {{ item.bankName + " " + item.accountNumber + " " + item.accountName }}
            </span>
          </template>
          <template v-slot:item.TranferBy="{ item }">
            {{ item.paidByName == null ? "" : item.paidByName }}
          </template>
          <template v-slot:item.DateTranfer="{ item }">
            {{ item.paid == null ? "" : fomatDateTime(item.paid) }}
            <!-- {{item.created}} -->
          </template>
          <template v-slot:item.BankTranfer="{ item }">
            {{ item.packhaiBankName == null ? "" : item.packhaiBankName }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="hidden-sm-and-up">
        <v-divider />
        <v-card
          elevation="0"
          class="my-1"
          v-for="(item, index) in dataTable"
          :key="index"
        >
          <v-row class="pa-1 py-5 mx-0">
            <v-col cols="8" align="left">
              <span v-if="item.statusId == 0" class="back--text darken-4">{{
                item.statusDescription
              }}</span>
              <span v-if="item.statusId == 1" class="orange--text darken-4">{{
                item.statusDescription
              }}</span>
              <span v-if="item.statusId == 2" class="success--text">{{
                item.statusDescription
              }}</span>
              <span v-if="item.statusId == 3" class="danger--text">{{
                item.statusDescription
              }}</span>
            </v-col>
            <v-col cols="4" align="right" v-if="item.statusId == 2"
              ><a @click="DetailTranfer(item)">รายละเอียด</a></v-col
            >
          </v-row>
          <v-divider />
          <v-row class="pa-1 py-2 mx-0">
            <v-col cols="12">
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> วันที่แจ้งชำระ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  {{ item.created == null ? "" : fomatDateTime(item.created) }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> ร้านค้า : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  {{ item.shopName == null ? "" : item.shopName }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> ยอดที่มี : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{
                    item.balanceLeft == null ? "0.00" : format_price(item.balanceLeft)
                  }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ยอดถอน</span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{
                    item.amount == null ? "0.00" : format_price(item.amount)
                  }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> สาขา : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  {{ item.branchName == null ? "" : item.branchName }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> ธนาคาร : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{ item.bankName }}</span
                  ><br />
                  <span>{{ item.accountNumber }}</span
                  ><br />
                  <span>{{ item.accountName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>โอนโดย : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{ item.paidByName == null ? "" : item.paidByName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>วันที่โอน : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{ item.paid == null ? "" : fomatDateTime(item.paid) }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ธนาคารที่โอน : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="left">
                  <span>{{
                    item.packhaiBankName == null ? "" : item.packhaiBankName
                  }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="pa-0 mx-0 py-0">
            <v-col
              class="px-0 px-1"
              cols="6"
              align="center"
              style="
                border-right: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                height: 50px;
              "
            >
              <v-btn
                text
                class="py-6"
                width="300px"
                v-if="item.statusId != 3 && item.statusId != 2"
                color="red"
                @click="cancletranfer(item)"
                >ยกเลิก</v-btn
              >
            </v-col>
            <v-col
              class="px-0 px-1"
              cols="6"
              align="center"
              style="height: 50px; border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
            >
              <v-btn
                text
                class="py-6"
                width="300px"
                v-if="item.statusId == 0"
                color="black"
                @click="ConfirmTranfer(item)"
              >
                ดำเนินการ
              </v-btn>
              <v-btn
                text
                class="py-6"
                width="300px"
                v-else-if="item.statusId == 1"
                color="orange"
                @click="opendialogupdatetranfer(item)"
              >
                แจ้งยอด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- <v-row  class="mx-0 mt-5">
          <v-col cols="12" align="center">
            <div class="text-center" @click="changePage()">
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="7"
                color="primary"
              ></v-pagination>
            </div>
          </v-col>
        </v-row> -->

    <v-dialog
      v-model="DialogTranfer"
      scrollable
      :fullscreen="isFullscreen"
      max-width="40%"
      persistent
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>แจ้งโอน</span>
          <v-spacer />
          <v-btn icon @click="DialogTranfer = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-form ref="Tranfer" lazy-validation>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>ธนาคาร :</h4>
              </v-col>
              <v-col cols="12" md="9" lg="10" class="pb-2">
                <v-select
                  ref="refbank"
                  v-model="tranfer.bank"
                  autocomplete="off"
                  :items="bank"
                  item-text="accountName"
                  item-value="id"
                  label="เลือกธนาคาร"
                  dense
                  hide-details
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    <v-col cols="11" class="mx-0 pl-0">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img :src="data.item.logo" />
                      </v-avatar>
                      {{
                        (data.item.accountNumber == null ? "" : data.item.accountNumber) +
                        " " +
                        data.item.accountName
                      }}
                    </v-col>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-col class="ml-0">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img :src="data.item.logo" />
                      </v-avatar>
                      {{
                        (data.item.accountNumber == null ? "" : data.item.accountNumber) +
                        " " +
                        data.item.accountName
                      }}
                    </v-col>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>ยอดเงิน :</h4>
              </v-col>
              <v-col cols="12" md="9" lg="10" class="pb-2">
                <v-text-field
                  :value="tranfer.amount"
                  @change="(value) => (tranfer.amount = value)"
                  ref="refAddAmount"
                  @keypress="isNumberWNoDot($event)"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row row>
              <v-col cols="12" md="3" lg="2" class="pb-2">
                <h4>วันที่ :</h4>
              </v-col>
              <v-col cols="12" md="9" lg="10" class="pb-2">
                <v-flex>
                  <v-datetime-picker
                    v-model="tranfer.date"
                    time-format="HH:mm:ss"
                    :time-picker-props="timeProps"
                    :text-field-props="textFieldProps"
                  >
                    <template slot="dateIcon">
                      <v-icon>fas fa-calendar</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>fas fa-clock</v-icon>
                    </template>
                    <template slot="actions" slot-scope="{ parent }">
                      <v-btn color="success darken-1" @click="parent.okHandler"
                        >Done</v-btn
                      >
                    </template>
                  </v-datetime-picker>
                </v-flex>
              </v-col>
            </v-row>
            <v-row row>
              <v-col class="pb-2" cols="12" md="3" lg="2">แนบไฟล์ :</v-col>
              <v-col class="pb-2" cols="12" md="9" lg="10">
                <v-row row>
                  <v-col class="pb-0" cols="12">
                    <v-btn
                      onclick="document.getElementById('fileInput').click()"
                      outlined
                      color="primary"
                      >แนบไฟล์</v-btn
                    >
                    <v-btn
                      v-if="tranfer.file != null"
                      icon
                      color="danger"
                      @click="tranfer.file = null"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                    <v-file-input
                      id="fileInput"
                      v-model="tranfer.file"
                      accept="image/*"
                      @change="set_data_file()"
                      style="display: none"
                    />
                  </v-col>
                </v-row>
                <v-row row>
                  <v-col class="pb-0" cols="12" md="6">
                    <v-img
                      class="mt-3 title mb-1"
                      v-if="this.imglogoPre != null && this.tranfer.file != null"
                      :src="imglogoPre"
                      @click="image_click(imglogoPre)"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            :elevation="1"
            color="primary"
            class="mr-1"
            @click="updatertranfercod()"
          >
            <v-icon left>mdi-content-save</v-icon>
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_detail"
      scrollable
      :fullscreen="isFullscreen"
      max-width="40%"
      persistent
    >
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <span>รายละเอียดถอน COD</span>
          <v-spacer />
          <v-btn icon @click="dialog_detail = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> ร้านค้า : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.shopName == null ? "-" : detail.shopName }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> สาขา : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.branchName == null ? "-" : detail.branchName }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> แจ้งโดย : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.createdByName == null ? "-" : detail.createdByName }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> ยอดถอน : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.amount == null ? "-" : format_price(detail.amount) }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> โอนโดย : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.paidByName == null ? "-" : detail.paidByName }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> วันที่โอน : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              {{ detail.paid == null ? "-" : fomatDateTime(detail.paid) }}
            </v-col>
          </v-row>
          <v-row row>
            <v-col cols="12" md="3" lg="2" class="pb-2"> สลิป : </v-col>
            <v-col cols="12" md="6" lg="6" class="pb-2">
              <p>
                <v-img
                  max-width="100"
                  class="mt-0 title mb-1"
                  v-if="detail.pic != null"
                  :src="detail.pic"
                  @click="image_click(detail.pic)"
                />
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="500">
      <!-- <v-card-title>
            <v-spacer/>
            <v-btn icon @click="dialog_photo=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title> -->
      <v-card class="my-0">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-img :src="dialog_photo_link" aspect-ratio="1.5" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from "@/website/components/Loading";
import axios from "axios";
import Swal from "sweetalert2";
import { branchService_dotnet } from "@/website/global";
import { isNumberWNoDot, isNumberWithDot, format_price, get_premission_manage, permission_alert } from "@/website/global_function";
import { token_api, header_token } from "@/website/token";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data: () => ({
    BranchID: parseInt(localStorage.getItem("Branch_BranchID")),
    page_loading: false,
    loading: false,
    isFullscreen: false,
    permission: null,
    StaffId: null,

    search_menu_date_from: false,
    search_menu_date_to: false,
    search_date_from: null,
    search_date_to: null,
    search: {
      status: "0,1",
    },
    radio_status: [
      { text: "ยังไม่โอน", value: "0,1" },
      { text: "โอนแล้ว", value: "2" },
      { text: "ทั้งหมด", value: "0,1,2" },
    ],

    headers: [
      { text: "ยกเลิก", align: "center", sortable: false, value: "Cancle" },
      { text: "ดำเนินการ", align: "center", sortable: false, value: "Confirm" },
      { text: "แจ้งยอด", align: "center", sortable: false, value: "Amount" },
      { text: "ID", align: "center", sortable: false, value: "id" },
      { text: "รายละเอียด", align: "center", sortable: false, value: "Detail" },
      { text: "สถานะ", align: "center", sortable: false, value: "Status" },
      { text: "วันที่แจ้ง", align: "center", sortable: false, value: "Created" },
      { text: "ร้านค้า", align: "center", sortable: false, value: "NameShop" },
      { text: "ยอดที่มี", align: "center", sortable: false, value: "Wallet" },
      { text: "ยอดถอน", align: "center", sortable: false, value: "Withdraw" },
      { text: "ธนาคาร", align: "left", sortable: false, value: "Bank" },
      { text: "โอนโดย", align: "left", sortable: false, value: "TranferBy" },
      { text: "วันที่โอน", align: "left", sortable: false, value: "DateTranfer" },
      { text: "ธนาคารที่โอน", align: "left", sortable: false, value: "BankTranfer" },
    ],
    dataTable: [],

    // paginations
    isPage: 0,
    page: 1,
    page_tmp: null,
    limits: 50,
    offsets: 0,
    pageLength: null,

    // DialogTranfer
    DialogTranfer: false,
    bank: [],
    shopName: null,
    tranfer: {
      codwithdrawid: null,
      date: null,
      bank: 0,
      amount: null,
      file: null,
    },

    textFieldProps: { appendIcon: "mdi-calendar" },
    timeProps: {
      useSeconds: true,
      ampmInTitle: true,
    },

    // upload file
    imglogoPre: null,
    dialog_photo_link: null,
    dialog_photo: false,

    //
    dialog_detail: false,
    detail: [],

    // export excel
    filename: null,
    sheetname: "แจ้งยอด COD",
    json_fields: [],
  }),
  computed: {
    computed_search_date_from() {
      return this.formatDates(this.search_date_from);
    },
    computed_search_date_to() {
      return this.formatDates(this.search_date_to);
    },
  },
  async created() {
    await this.load_data();
    await this.getbank();
    this.StaffId = parseInt(localStorage.getItem("Branch_StaffID"));

    this.permission = JSON.parse(localStorage.getItem("permission"));
    this.page_loading = false;
  },
  methods: {
    format_price,
    isNumberWithDot,
    isNumberWNoDot,
    get_premission_manage,
    permission_alert,
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${year}-${month}-${day}`;
      }
    },
    fomatDateTime(date) {
      if (date == null) {
        return null;
      } else {
        return moment(String(date)).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    async getbank() {
      this.loading = true;
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodReport/get-bankcod",
            { branchId: this.BranchID },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((bank) => {
            if (bank.status == 200) {
              this.bank = bank.data;
              var ba = { accountName: "--กรุณาเลือกธนาคาร--", id: 0, logo: null };
              this.bank.splice(0, 0, ba);
            }
          })
        );
      this.loading = false;
    },
    async load_data() {
      this.loading = true;
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodWithDraw/get-withdrawcod-list",
            {
              DateFrom: this.search_date_from,
              DateTo: this.search_date_to,
              status: this.search.status,
              isAscending: true,
              skip: this.offsets,
              take: this.limits,
              branchId: localStorage.getItem("Branch_BranchID"),
            },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((data) => {
            if (data.status == 200) {
              this.dataTable = data.data.withdrawCodList;
              this.search_date_from = data.data.dateFrom;
              this.search_date_to = data.data.dateTo;
              // this.pageLength =  (Math.ceil(Report.data.searchResultCount/this.limits))
              // if (this.pageLength<=0){this.pageLength=1}
              // this.pageTemp = this.page
            }
          })
        );
      this.loading = false;
    },
    // upload-delete slip
    async set_data_file() {
      var fileToBase64 = null;
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      if (this.tranfer.file != null) {
        fileToBase64 = await toBase64(this.tranfer.file);
        this.imglogoPre = fileToBase64;
      } else {
        fileToBase64 = null;
      }
    },
    opendialogupdatetranfer(item) {
      if (this.get_premission_manage(25) == false) {
        this.permission_alert()
        return
      }
      
      this.cleardialogupdatetranfer();
      this.tranfer.codwithdrawid = item.id;
      this.tranfer.amount = item.amount;
      this.shopName = item.shopName;
      this.DialogTranfer = true;
    },
    cleardialogupdatetranfer() {
      this.tranfer = {
        codwithdrawid: null,
        date: new Date(),
        bank: 0,
        amount: null,
        file: null,
      };
    },
    async updatertranfercod() {
      if (this.tranfer.amount == null || this.tranfer.amount == "") {
        this.$refs.refAddAmount.focus();
        return;
      }
      Swal.fire({
        icon: "warning",
        title: "ต้องการแจ้งยอดตามคำขอถอนเงิน ?",
        text: "ร้าน " + this.shopName + " ยอดถอด " + this.tranfer.amount + " บาท ",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          await axios
            .all([
              axios.post(
                branchService_dotnet + "CodWithDraw/update-tranfercod",
                {
                  CodwithdrawId: this.tranfer.codwithdrawid,
                  Paid: this.tranfer.date,
                  Amount: this.tranfer.amount,
                  PackhaiBankId: this.tranfer.bank,
                  PaidBy: this.StaffId,
                  Pic: this.imglogoPre,
                },
                { headers: header_token }
              ),
            ])
            .then(
              axios.spread((data) => {
                if (data.status == 200) {
                  this.DialogTranfer = false;
                  this.load_data();
                  if (
                    data.data.errorDescription == null ||
                    data.data.errorDescription == ""
                  ) {
                    Swal.fire({
                      toast: true,
                      timer: 1500,
                      showConfirmButton: false,
                      position: "top",
                      icon: "success",
                      title: "แจ้งดำเนินการสำเร็จ",
                    });
                  } else {
                    Swal.fire({
                      showConfirmButton: true,
                      position: "center",
                      icon: "warning",
                      title: data.data.errorDescription,
                    });
                  }
                }
              })
            );
        }
        this.loading = false;
      });
    },
    async cancletranfer(item) {
      if (this.get_premission_manage(25) == false) {
        this.permission_alert()
        return
      }
      Swal.fire({
        icon: "warning",
        title: "ต้องการยกเลิก ใช่ หรือ ไม่",
        text: "ร้าน " + item.shopName + " ยอดถอด " + item.amount + " บาท ",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          await axios
            .all([
              axios.post(
                branchService_dotnet + "CodWithDraw/cancle-tranfercod",
                {
                  Id: item.id,
                },
                { headers: header_token }
              ),
            ])
            .then(
              axios.spread((data) => {
                if (data.status == 200) {
                  this.load_data();
                  Swal.fire({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: "top",
                    icon: "success",
                    title: "ยกเลิกสำเร็จ",
                  });
                }
              })
            );
          this.loading = false;
        }
      });
    },
    async ConfirmTranfer(item) {
      if (this.get_premission_manage(25) == false) {
        this.permission_alert()
        return
      }

      Swal.fire({
        icon: "warning",
        title: "ต้องการดำเนินการตามคำขอถอนเงิน ?",
        text: "ร้าน " + item.shopName + " ยอดถอด " + item.amount + " บาท ",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          await axios
            .all([
              axios.post(
                branchService_dotnet + "CodWithDraw/confirm-tranfercod",
                {
                  Id: item.id,
                },
                { headers: header_token }
              ),
            ])
            .then(
              axios.spread((data) => {
                if (data.status == 200) {
                  if (
                    data.data.errorDescription != null &&
                    data.data.errorDescription != ""
                  ) {
                    Swal.fire({
                      showConfirmButton: true,
                      position: "center",
                      icon: "warning",
                      title: data.data.errorDescription,
                    });
                  }
                  for (var i in this.dataTable) {
                    if (item.id == this.dataTable[i]["id"]) {
                      this.dataTable[i]["statusId"] = data.data.statusId;
                      this.dataTable[i]["statusDescription"] =
                        data.data.statusDescription;
                    }
                  }
                }
              })
            );
          this.loading = false;
        }
      });
    },
    async DetailTranfer(item) {
      this.loading = true;
      await axios
        .all([
          axios.post(
            branchService_dotnet + "CodWithDraw/detail-tranfercod",
            {
              Id: item.id,
            },
            { headers: header_token }
          ),
        ])
        .then(
          axios.spread((data) => {
            if (data.status == 200) {
              this.detail = data.data;
              this.dialog_detail = true;
            }
          })
        );
      this.loading = false;
    },
    image_click(photo_link) {
      this.dialog_photo_link = photo_link;
      this.dialog_photo = true;
    },
    exportexcel() {
      var d = new Date();
      var hour = d.getHours() % 24; // AM,PM format
      var minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
      var time = hour + minutes;
      var date = new Date().toISOString().substr(0, 10);
      this.filename = "WithdrawCod_" + date + "_" + time + ".xlsx";
      this.json_fields = [
        { label: "สถานะ", field: "statusDescription", width: 15 },
        {
          label: "วันที่แจ้ง",
          field: "created",
          dataFormat: this.fomatDateTime,
          width: 20,
        },
        { label: "ร้านค้า", field: "shopName", width: 12 },
        { label: "ยอดถอน", field: "amount", dataFormat: format_price, width: 10 },
        { label: "สาขา", field: "branchName", width: 15 },
        { label: "ชื่อธนาคาร", field: "bankName", width: 15 },
        { label: "เลขธนาคาร", field: "accountNumber", width: 12 },
        { label: "ชื่อบัญชี", field: "accountName", width: 20 },
        { label: "โอนโดย", field: "paidByName", width: 15 },
        { label: "วันที่โอน", field: "paid", dataFormat: this.fomatDateTime, width: 20 },
        { label: "ธนาคารที่โอน", field: "packhaiBankName", width: 15 },
      ];
      return this.filename, this.json_fields;
    },
    format_account(val1, val2) {
      return val1 + " " + val2;
    },
  },
};
</script>
